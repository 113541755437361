import {
	INQUIRIES,
} from '../actions/types';

const initialState = {
	inquiryTypes: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case INQUIRIES:
			return {
				...state,
				inquiryTypes: action.payload
			};
		default:
			return state;
	}
}
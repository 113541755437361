import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import logo from '../../assets/img/DF_Logo.png';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'none !important',
        [theme.breakpoints.down('sm')]: {
          flexGrow: 1,
          display: 'block',
        },
      },
  list: {
    width: 250,
  },
  logo: {
    width: '160px',
    padding: '15px',
    margin: '0 auto',
    display: 'block',
  },
  active: {
    background: 'rgba(0, 0, 0, 0.05) !important',
    color: '#05ce78 !important'
  },
  activeText: {
      fontWeight: 700,
  }

  }));

function ListItemLink(props) {
    return <ListItem button component="a"  {...props} />;
  }

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const toggleDrawer = props.toggleDrawer;
  const left = props.left;
  let active  = props.active;


  const list = (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
         <img src={logo} className={classes.logo} alt="logo" />
         <Divider />
      <List>
          <Link to="/" style={{textDecoration: 'none', color: "rgba(0, 0, 0, 0.54)"}}    >
            <ListItem className={ active === 0 ? classes.active : ''}>
            <ListItemIcon>{<HomeIcon style={ active === 0 ?  {color: '#05ce78'} : {} } />}</ListItemIcon>
            <Typography className={active === 0 ?  classes.activeText : ''} >HOME</Typography>
                </ListItem>
          </Link>
          <Link to='/about-us' style={{textDecoration: 'none',  color: "rgba(0, 0, 0, 0.54)" }}   >
            <ListItem className={active === 1 ? classes.active : ''}>
            <ListItemIcon>{<PeopleIcon style={active === 1 ?  {color: '#05ce78'} : {}} />}</ListItemIcon>
            <Typography className={active === 1 ?  classes.activeText : ''} >ABOUT US</Typography>
            </ListItem>
          </Link>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
          <Drawer anchor={"left"} open={left} onClose={toggleDrawer(false)}>
            {list}
          </Drawer>
    </div>
  );
}
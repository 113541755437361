import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography,InputBase, ExpansionPanel , ExpansionPanelDetails , ExpansionPanelSummary} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import SearchIcon from '@material-ui/icons/Search';
import FAQs from './faqQuestions.json';
import './style.css'

const styles =  theme => ({
handContent : {
	background: '#FEC553',
	padding: '10px 15px',
	textAlign: 'center',
    borderRadius:'4px 4px 0px 0px',
    marginTop :40
},
donateContent : {
    border: '2px solid #FEC553',
	background: '#fff',
	padding: '15px 15px 20px',
    borderRadius: '0px 0px 4px 4px'
},
donateButton : {
    padding : 10,
    background: '#FEC553',
    borderRadius: 3,
    color: 'white',
    border : 0,
    width: '100%',
    marginBottom : 10,
    '&:hover': {
        background: '#FEC553'
    }
},
donate : {
    color: '#666',
	fontSize: 14,
	fontFamily: 'Open Sans',
	lineHeight: 1.5
},
iconColor : {
    background: '#f9f9f9',
    color: '#294C5C',
    cursor: 'pointer'
},
root: {
    width: '100%',
},
question: {
    color: '#294C5C',
	fontFamily: 'Roboto Slab',
	fontSize: 16,
	cursor: 'pointer',
	lineHeight: 1.5
},
activeColor : {
    fontWeight: 'bold'
},
answer : {
    fontSize: "14px",
    color: "#4C5E67",
    opacity: 0.8,
    letterSpacing: "0.18px",
    lineHeight: '32px',
    fontFamily: "Open Sans",
    paddingRight: '20px',
    paddingBottom: '30px',
    '@media (max-width:600px)' : {
        paddingRight: 0
    }
},
searchBox :{
	marginBottom: 35
},
searchButton : {
    width: '100%',
	height: 45,
	background: '#05CE78',
	color: '#fff',
    border: 'none',
    borderRadius: '0px 4px 4px 0px', 
	textTransform: 'uppercase',
	fontWeight: 500,
    fontFamily: 'Roboto',
    '&:hover': {
        background: '#00C9A8'
    }
},
searchField : {
    display: 'block',
    width: '100%',
    fontSize: '16px',
    lineHeight: 0,
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #D3D8DA !important',
    backgroundSize: '0 !important',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    borderRadius: '5px !important',
    paddingLeft: '42px',
    height: '56px',
    paddingTop: '13px',
    textIndent: '30px',
},
searchIcon : {
    position: 'absolute',
	marginTop: '18px',
	marginLeft: '14px',
    color: '#7F91A2',
    zIndex : 1
},
rounded: {
    borderBottom: '1px solid lightgray'
},
PanelRoot : {
    boxShadow : 'none !important',
    '&:before':{
        display: 'none'
    }
},
FD_aboutText:  {
    fontSize: "18px",
    color: "#294C5C",
    fontFamily: "roboto",

},
faqSectionItem: {
    maxWidth: "1140px",
    width: '100%',
    margin: "0 auto",
},

});

class FAQS extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            panel : 'panel0',
            faqs : FAQs
        }
    }

    handleChange = (panel) =>{
        this.setState({
            panel : panel
        })
    }
    
    // componentDidMount(){
    //     this.props.GetAllFAQS();
    // }

    onRemoveClick = () =>{
        this.setState({
            panel : false
        })
    }


    render (){
        const { classes } = this.props;
        const { panel , faqs } = this.state;
        //console.log('faqs', faqs);
    
        return (
            <Grid container xs={12} spacing={3} className={classes.faqSectionItem} >
                 <Grid item xs={12} md={12}>
                    <Grid container justify="center" alignItems="center">
                        {/*<Grid item  md={6} xs={12} className={classes.searchBox }>
                            
                            <SearchIcon className={classes.searchIcon}  />

                            <InputBase 
                                id="outlined-basic" 
                                label="Search" 
                                placeholder="Type Keyword.." 
                                className={ classes.searchField }
                                inputProps={{ 'aria-label': 'naked' }}
                            />
                        </Grid>*/}
                    </Grid>

                    {faqs && faqs.map((faq, index) => (

                    <ExpansionPanel  classes={{ root:classes.PanelRoot, rounded : classes.rounded }} style={{backgroundColor: '#f9f9f9'}} expanded={panel === `panel${index}`} >
                        <ExpansionPanelSummary
                            expandIcon={panel === `panel${index}` ? <RemoveCircleIcon className={ classes.iconColor} onClick={this.onRemoveClick }/> : <AddCircleIcon className={ classes.iconColor } onClick={() => this.handleChange(`panel${index}`)} />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography onClick={() => this.handleChange(`panel${index}`)} className={`${classes.FD_aboutText}  ${panel === `panel${index}` && classes.activeColor  } `}>0{index + 1 }. {faq.question} </Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails>
                            <Typography className={ classes.answer }>
                                {faq.answer}
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    ))}

                </Grid>   
            </Grid>
            
        );
    }
}


export default withStyles(styles)(FAQS);

import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import logo from '../../assets/img/DF_Logo.png';

export class PageNotFound extends Component {
    render() {
        return (
            <div className="">
                <div className="page_body">

                    <div className="error_page_header">
                        <div className="main_container">
                        <Link to="/" className="logo_link"><img src={logo} className="logo" alt="logo" /></Link>
                        </div>
                    </div>

                        <div className="page_inner_container">
                            <h1>Page not found</h1>
                            <div className="par_dl">We can’t seem to find the page you are looking for. If you need help, visit our <Link to="/about-us#contact-us">Help center</Link></div>
                            <div><Link className="go_btn" to="/">GO TO HOME PAGE</Link></div>
                            

                        </div>

                </div>


            </div>
        )
    }
}

export default PageNotFound

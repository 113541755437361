import React from 'react'
import { Collapse, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';


export default function SuccessAlert(props) {


    return (
        <div style={{ position: 'sticky', top: 0, zIndex: 999 }}>
            <Collapse in={props.open}>
                <Alert
                    severity="success"
                    variant="filled"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                props.setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {props.msg}
                </Alert>
            </Collapse>
        </div>
    )
}

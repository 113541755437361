import React from 'react';
import { Grid, Divider, Container, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import KhushuPattern from '../../assets/img/Khushu-Pattern.png';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment'
import LockIcon from '@material-ui/icons/Lock';





const useStyles = makeStyles((theme) => ({
    FD_banner_img: {
        background: `#8ba4b9 url(${KhushuPattern})`,
        backgroundSize: "cover",
        opacity: 1,
        backgroundPosition: "center",
        paddingTop: "150px",
        paddingBottom: "150px",
        [theme.breakpoints.down('xs')]: {
            paddingTop: "150px",
            paddingBottom: "150px",
        }
    },
    FD_inner_banner: {
        padding: '24px'
    },
    FD_Hometitle: {
        fontWeight: "700",
        fontSize: "48px",
        margin: "10px 0",
        letterSpacing: "0.48px",
        color: "white",
        //maxWidth: '600px',
        lineHeight: 1.1,
        [theme.breakpoints.down('xs')]: {
            fontSize: "30px",
            maxWidth: "100%",
            lineHeight: "inherit"
    },
    },
    FD_homeBanner: {
        textAlign: "left",
        color: "#fff",
        [theme.breakpoints.down('xs')]: {
            padding: "0 64px"
    },
    '@media (max-width: 599.95px)': {
        padding: "0 35px",
    },
    [theme.breakpoints.down('sm')]: {
        padding: "0 20px",
    }
    },
    FD_line : {
        background: "#DAE2E9",
        height: "3px",
        maxWidth: "100px",
        borderRadius: "100px",
        //margin: "30px auto 30px 0px",
        margin: '0 auto',
        marginTop:'25px',
        marginBottom:'25px',
        '@media (max-width:959.99px)': {
            margin: '30px auto',
            textAlign: 'center'
        },
    },
    FD_verse: {
        color: "white",
        fontSize: "20px",
        opacity: 0.8,
        fontWeight: 600,
        textAlign: 'left',
        fontFamily: "Open Sans",
        margin: "0px 0px",
        textAlign:'center',
        '@media (max-width: 959.99px)': {
            margin: '0px auto',
            textAlign: 'center'
        },
    },
    FD_donor: {
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "18px",
        color: "white",
        '@media (max-width: 599.95px)': {
            fontSize: "14px",
        },
    },
    donation_form: {
        position: 'relative',
        zIndex: 999,
        background: '#fff',
        borderRadius: '5px',
        padding: '0px',
        maxWidth: '400px',
        margin: '0 auto 0 0',
        [theme.breakpoints.down('md')]: {
            maxWidth: 'unset',
            
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'unset',
            
        }
    },
    dhead: {
        background: '#F9FCFF',
        backgroundSize: 'cover',
        position: 'relative',
        padding: '20px 16px',
        color: '#294C5C',
        textAlign: 'center',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        fontSize: '24px',
        borderBottom: '#ccc 1px solid',
        margin: 0,
        fontWeight: 600,
    },
    '& form': {
        marginBottom: 0,
    },
    home_donation: {
        padding: '28px 30px 0px',
        position: 'relative',

    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft:"-11px !important",
    },
    donation_form_dollarchk: {
        display: 'inline-block',
        width: '100%',
        height: '56px',
        lineHeight: 1.5,
        border: '1px solid #E4E8EC',
        borderRadius: '4px',
        fontSize: '32px',
        color: '#294C5C !important',
        backgroundColor: '#fff',
        cursor: 'pointer',
        textAlign: 'center',
        fontWeight: 700,
        margin:3,
        '& .MuiSvgIcon-root': {
            display: 'none !important'
        },
        '& span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
            display: 'contents',
            fontSize: '20px !important',
            color: '#294C5C !important',
            display:"block",
            verticalAlign: "top",
        },
        '& .MuiFormControlLabel-root': {
            margin: '0px',
            position: 'relative',
            left: '-8px'
        },
        '&:hover': {
            backgroundColor: '#05CE78',
            color: "#fff",
            border: "1px solid #05CE78",
            '& span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
               color: '#fff !important'
            },
          },
          '@media (max-width: 430px)': {
            '& span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
                fontSize: '24px !important',  
            },
        },
    },
    input_group: {
        position: 'relative',
        display: 'flex',
        'flex-wrap': 'wrap',
        alignItems: 'stretch',
        background: 'white !important',
        width: '101%',
        marginLeft:"2px",
        '& .MuiInputAdornment-root.MuiInputAdornment-positionStart': {
            paddingTop: '3px',
            margin: 0,
            color: '#294C5C !important',
            fontSize: '20px',
            marginTop: '0px !important',
            width: '20px',
            paddingBottom: '12px',
            fontFamily: "Open Sans",
            fontWeight: 600,
            paddingLeft:16,
        },
        '& .MuiInputAdornment-root.MuiInputAdornment-positionStart span': {
            width: '45px',
            fontSize: '20px',
            color: '#294C5C !important',
            marginTop: '0px !important',
            position: 'relative',
            left: '0px',
            bottom: '0px',
        },
        '& .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart': {
            fontSize: '20px',
            color: '#294C5C',
            padding: '0px',
            position:"relative",
            top:"-3px",
        },
        '& .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart::placeholder': {
            fontSize: '20px',
            fontWeight: '500',
            position:"relative",
            top:"0",
            color: '#294C5C !important'
        },
        '& .MuiInput-root': {
            borderRadius: '4px',
            border: '1px solid #E4E8EC !important',
            height:56,
            marginTop:4,
            paddingTop:6,
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'none !important'
        }
    },
    activeNumber: {
        backgroundColor: '#05CE78 !important',
        color: "#white !important",
        border: "1px solid #05CE78",
        '& span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
           color: 'white !important'
        }
    },
    donation_form_btn_group_btn_group_vertical: {
        position: 'relative',
        display: '-ms-inline-flexbox',
        display: 'inline-block',
        'vertical-align': 'middle',
        marginTop: '10px',
        padding: '0px 30px 30px',
    },
    btncell: {
        display: 'inline-block',
        width: '100%',
        height: '52px',
        lineHeight: 1.5,
        border: '1px solid #E4E8EC',
        backgroundColor: '#fff',
        color: '#6C8FA4',
        cursor: 'pointer',
        textAlign: 'center',
        fontWeight: 700,

        '@media (max-width:768px)': {
            marginBottom:"15px !important",
        },

        '& .MuiSvgIcon-root': {
            display: 'none !important'
        },
        '& span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
            display: 'contents',
            fontSize: '14px !important',
            fontWeight: 500,
            textTransform: 'uppercase',
        },
        '& .MuiFormControlLabel-root': {
            marginTop: '13px'
        },
          '@media (max-width: 430px)': {
            '& span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
                fontSize: '14px !important',               
            },
        },
    },
    donation_form_proceed_btn: {
        backgroundColor: '#05CE78 !important',
        color: '#fff',
        padding: '15px',
        marginBottom: '0px',
        border: 'none',
        fontWeight: 500,
        fontSize: '14px',
        textAlign: 'center',
        height: '50px',
        marginTop: 10,
        width: '100%',
        borderRadius: '4px',
        letterSpacing: '2px',
        cursor: 'pointer',

        '@media (max-width: 768px)': {
            marginTop: 0,               
            },
        

        [theme.breakpoints.down('sm')]: {
            fontSize: '11px',
            padding: '6px',
           
        },

        '& .MuiButton-label .MuiSvgIcon-root': {
            width:14,
        },

    },
    FD_mobileBanner: {
        '@media (max-width: 1250px)': {
            textAlign: 'center',
            paddingBottom: '20px',
        },

        
    }
 
}));


const Banner = (props) =>  {
    const {connectingPara} = props;

    const [value, setValue] = React.useState();

    const handleChange = (event) => {
        
      setValue(event.target.value);
    };

    const [duration, setDuration] = React.useState('monthly');

    const handleDuration = (newValue) => {
        
        setDuration(newValue);
    };
    const outerHandleChange = (newvalue) => {

        setValue(newvalue);
    }
    const classes = useStyles();
    return (

        <div className={classes.FD_banner_img}>
            <div className="main_container banner_container">

        
            <div className="banner_left_col">
                <Typography  component="div">
                    <Typography className={classes.FD_donor}>
                        {connectingPara}    
                    </Typography>
                    <Typography className={classes.FD_Hometitle} component="h2">
                        The Reward of Goodnes 
                    </Typography>
                    <Typography className={classes.FD_Hometitle} component="h2">
                        is Nothing but Goodness
                    </Typography>
                    <Divider className={classes.FD_line} variant="fullWidth" />
                    <Typography className={classes.FD_verse}>
                        AL-QURAN 55:61
                    </Typography>
                </Typography>
            </div>

            {/* <div className="banner_donation_form">

                        <Grid  className={classes.FD_banner_img} >
                <Grid container className={classes.FD_footerInner}  >
                    <Grid className={classes.FD_homeBanner} container xs={12} direction="row" alignItems="center" justify="flex-start" spacing={0} >
                
                

                <Grid>
                    <Grid item className={classes.donation_form}>
                    <Grid
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        className={classes.formContainer}
                    >
                        <Grid item md={12} sm={12} xs={12} style={{textAlign: 'center'}}>
                        <Typography className={classes.dhead} component="h3">
                            I want to donate
                        </Typography>
                        </Grid>

                        <Grid item md={12} sm={12} xs={12}> 
                       
                            <Container className={classes.home_donation}>
                                <Grid container spacing='2' direction="row" className={classes.row} >

                                    <Grid item md={4} sm={6} xs={6}  >
                                            <RadioGroup aria-label="price"  onClick={() => outerHandleChange(25)} className={classes.donation_form_dollarchk+' '+(value === 25 ? classes.activeNumber : '')} name="price" value={value || ''}>
                                                <FormControlLabel value="25" control={<Radio />} label="$25" />
                                            </RadioGroup>  
                                                                  
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6}>
                                        <RadioGroup aria-label="price" className={classes.donation_form_dollarchk+' '+(value === 50 ? classes.activeNumber : '')} name="price" value={value || ''} onClick={() => outerHandleChange(50)}>
                                            <FormControlLabel value="50" control={<Radio />} label="$50" />
                                        </RadioGroup>                             
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6}>
                                        <RadioGroup aria-label="price" className={classes.donation_form_dollarchk+' '+(value === 100 ? classes.activeNumber : '')} name="price" value={value || ''} onClick={() => outerHandleChange(100)}>
                                            <FormControlLabel value="100" control={<Radio />} label="$100" />
                                        </RadioGroup>                             
                                    </Grid>
       
                                    <Grid item md={4} sm={6} xs={6}>
                                        <RadioGroup aria-label="price" className={classes.donation_form_dollarchk+' '+(value === 200 ? classes.activeNumber : '')} name="price" value={value || ''} onClick={() => outerHandleChange(200)}>
                                            <FormControlLabel value="200" control={<Radio />} label="$200" />
                                        </RadioGroup>                             
                                    </Grid>

                                    <Grid item md={8} sm={12} xs={12}>
                                        <TextField
                                            className={classes.input_group}
                                            id="input-with-icon-textfield"
                                            placeholder="Other amount"
                                            value={value || ''}
                                            onChange={handleChange}
                                            InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography component='span'>$</Typography>
                                                </InputAdornment>
                                            ),
                                            }}
                                        />                             
                                    </Grid>

                                </Grid>
                            </Container>
              
                        </Grid>

                        <Grid item md={12} sm={12} xs={12}>
                            <Container className={classes.donation_form_btn_group_btn_group_vertical}>
                                <Grid container direction="row">

                                    <Grid item md={6} sm={6} xs={12}>
                                        <RadioGroup aria-label="duration" style={{borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px'}} className={classes.btncell+' '+(duration === 'one time' ? classes.activeNumber : '')} name="duration" value={duration || ''} onClick={() => handleDuration('one time')}>
                                            <FormControlLabel value="one time" control={<Radio />} 
                                            label={[
                                            <i className="fas fa-hand-holding-heart" style={{paddingRight: '10px',}} ></i>, 
                                            // <img src={loveSvg} style={{paddingRight: '10px'}} />,
                                            'One Time']} />
                                        </RadioGroup>                             
                                    </Grid>

                                    <Grid item md={6} sm={6} xs={12}>
                                        <RadioGroup aria-label="duration" style={{borderTopRightRadius: '4px', borderBottomRightRadius: '4px'}} className={classes.btncell+' '+(duration === 'monthly' ? classes.activeNumber : '')} name="duration" value={duration || ''} onClick={() => handleDuration('monthly')}>
                                            <FormControlLabel value="monthly" control={<Radio />} 
                                            label={[
                                                <i className="fas fa-sync-alt" style={{paddingRight: '10px'}}></i>,
                                            // <img src={monthlySvg} style={{paddingRight: '10px'}} />,
                                            'Monthly']}  />
                                        </RadioGroup>                             
                                    </Grid>

                                    <Grid item md={12} sm={12} xs={12} style={{marginTop: '16px'}}>
                                        <Button className={classes.donation_form_proceed_btn} variant="contained" >
                                        <LockIcon /> &nbsp; proceed next</Button>                             
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>



                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    

                    </Grid>

            </div> */}

        </div>


    
    </div>   
    );
}

export default Banner;
import React from 'react';
import { Grid, Divider , Zoom, Slide  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import greenPattern from '../../assets/img/green_pattren.png';
import slider1 from '../../assets/img/slider-1.png';
import slider2 from '../../assets/img/slider-2.png';
import slider3 from '../../assets/img/slider-3.png';
import slider4 from '../../assets/img/slider-4.png';
import slider5 from '../../assets/img/slider-5.png';

import './style.css';


const styles =  theme => ({
    FD_testimonial: {
        backgroundImage: `url(${greenPattern})`,
        textAlign: "center",
        color: "#fff",
        paddingTop: "80px",
        marginTop: "90px",
       // marginBottom: "250px !important",
       // height: '550px',

        '@media (max-width: 959px)': {
            //height: '550px',
            position:"relative",
        },
        
    },

    list_item_text:{
        
    },


    FD_noticeTitle: {
        fontSize: "36px",
        fontWeight: "bold",
        margin: "10px 0",
        textTransform: 'uppercase',
        '@media(max-width:959px)': {
            fontSize: '28px',
        }
        
    },
    FD_whiteLine: {
        background: "#fff",
        height: "2px",
        maxWidth: "100px",
        borderRadius: "100px",
        margin: "10px auto",
        opacity: 0.8,
    },
    FD_noticeText: {
        fontSize: "22px",
        opacity: 0.8,
        color: "#fff",
        letterSpacing:'1px', 
        '@media(max-width:959px)': {
            fontSize: '18px',
        }

    },
    FD_sliderContainer:{
        height: '100%',
        width: '100%',

        '@media(max-width:959px)': {
            height: '300px',
        }
    },
    FD_sliderContent: {
        display: "flex",
        maxWidth: "1140px",
        margin: "0 auto",
        alignItems: "center",
        position: "relative",
        top: "0px",
        overflow: 'hidden',
        marginTop:50,

        '@media(max-width:959px)': {
            overflow: 'visible',
            position: "initial",
        }
    },
    FD_sliderLeftIcons: {
        border: "1px solid",
        borderRadius: "64px",
        padding: "7px",
        fontWeight: "700 !important",
        textAlign: "center",
        fontSize: "56px !important",
        position:"relative",
        top:"0px",
        '@media(max-width:768px)': {
            position:'absolute',
            right:'0',
            bottom:'0',
            top:'auto',
    
        }
    },


    FD_sliderRightIcons: {
        border: "1px solid",
        borderRadius: "64px",
        padding: "7px",
        fontWeight: "700 !important",
        textAlign: "center",
        fontSize: "56px !important",
        position:"relative",
        top:"0px",
        '@media(max-width:768px)': {
            position:'absolute',
            left: '0',
            bottom:'0',
            top:'auto',
    
        }
    },
    material_icons: {
        fontFamily: 'Material Icons',
        fontWeight: "normal",
        fontStyle: "normal",
        fontSize: "24px",
        lineHeight: 1,
        letterSpacing: "normal",
        textTransform: "none",
        display: "inline-block",
        whiteSpace: "nowrap",
        wordWrap: "normal",
        direction: "ltr",
        "-webkit-font-feature-settings": 'liga',
        "-webkit-font-smoothing": "antialiased",
        cursor: 'pointer',
        '@media(max-width:959px)': {
            margin:"15px",
        }
    },
    FD_sliderTextContent: {
        fontSize: "30px",
        lineHeight:'50px',
        padding: "0 50px",
        fontFamily : 'Open Sans',
        height:"280px",
        letterSpacing:"0.3px",
        
        [theme.breakpoints.down('sm')]: {
            padding: "0px 18px",
            fontSize: "18px",
            display: "block",
            textAlign: "center",
            letterSpacing:"0px",
            height:"210px",
            lineHeight: "34px",
        },
        '@media(max-width:959px)': {
            fontSize: '16px',
            overflow:'scroll',
        }
    },
    FD_inactiveSliderImge: {
        height : 101,
        width : 101,
        [theme.breakpoints.down('sm')]: {
            display: "none",
        

        }
    },
    FD_activeSliderImge: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }

    },
    FD_sliderImages: {
        maxWidth: "1140px",
        margin: "0 auto",
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
        padding: "0 20px",
        marginTop:"30px",

        '@media(max-width:959px)': {
            display: "block",
        }
    },
    FD_figText: {
        fontSize: "14px",
        color: "#4C5E67",
        opacity: 0.8,
        margin: 0,
        '@media(min-width:426px)': {
            fontSize: '18px',
            marginTop: '6px',
        }
    },
    FD_figTitle: {
        fontSize: "16px",
        margin: 0,
        color: "#294C5C",
        fontWeight: "bold",
        '@media(min-width:426px)': {
            fontSize: '22px',
            marginTop: '15px',
        }
    },
    overlay: {
        width: '100%',
        height: '101px',
        background: 'black',
        borderRadius: '184px',
        zIndex: 99999999999999999999,
        position: 'absolute',
        marginTop: '-105px',
        opacity: 0.6,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            display: "none",
        

        }
    }
});

class HomeCarousel extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            index : 0
        }
    }

    nextClick= () =>{
        const nextIndex = (this.state.index+1)%5
        this.setState({
            index : nextIndex
        })
    }
  
   
    prevClick= () =>{
  
        let nextIndex = ''
  
        if(this.state.index === 0 ){
            nextIndex = 4
        }
        else{
            nextIndex = this.state.index-1
        }
  
        this.setState({
            index : nextIndex
        })
    } 

    activeIndex = (nextIndex) => {

        this.setState({
            index : nextIndex
        })

    }
    
    render(){

        const {classes} = this.props;
        const { index } =  this.state;

        return (
            <Grid  direction="column" className={classes.FD_testimonial} >
                <div className="main_container">
                <Typography className={classes.FD_noticeTitle} variant="h1" component="h1">
                        GIVING IS A NEVER-ENDING DEED
                    <Divider className={classes.FD_whiteLine} />
                </Typography>
                <Typography className={classes.FD_noticeText} variant="body1" component="p">
                    Give to organizations of your choice and help great efforts.
                </Typography>


                <Grid direction="column" className={classes.FD_sliderContainer} >
                    
                    <Grid item className={classes.FD_sliderContent}>
                        <Typography className={classes.FD_sliderArrow}  style={{padding: '0px'}} variant="body2" component="span">
                            <ChevronLeftIcon className={classes.material_icons + ' ' +classes.FD_sliderRightIcons} onClick={this.prevClick} />
                        </Typography>

                        
                        <Slide direction="left" in={index === 0 ? true : false} timeout={{ enter: 1000,}} mountOnEnter unmountOnExit>
                            <Typography className={classes.FD_sliderTextContent} variant="body2" component="span">
                                Those who spend their wealth in charity day and night, secretly and openly—their reward is with their Lord, and there will be no fear for them, nor will they grieve. Al Quran - 2:274
                            </Typography>
                        </Slide>

                        <Slide direction="left" in={index === 1 ? true : false} timeout={{ enter: 1000}} mountOnEnter unmountOnExit>
                            <Typography className={classes.FD_sliderTextContent} variant="body2" component="span">
                                “When a man dies, his deeds come to an end except for three things: Sadaqah Jariyah (ceaseless charity); a knowledge which is beneficial, or a virtuous descendant who prays for him (for the deceased).” (Muslim)
                            </Typography>
                        </Slide>

                        <Slide direction="left" in={index === 2 ? true : false} timeout={{ enter: 1000}} mountOnEnter unmountOnExit>
                            <Typography className={classes.FD_sliderTextContent} variant="body2" component="span">
                                “Charity does not in any way decrease the wealth and the servant who forgives, Allah adds to his respect; and the one who shows humility, Allah elevates him in the estimation (of the people).” (Muslim)
                            </Typography>
                        </Slide>

                        <Slide direction="left" in={index === 3 ? true : false} timeout={{ enter: 1000}} mountOnEnter unmountOnExit>
                            <Typography className={classes.FD_sliderTextContent} variant="body2" component="span">
                                 “Give charity without delay, for it stands in the way of calamity.” (Al-Tirmidhi)
                            </Typography>
                        </Slide>

                        <Slide direction="left" in={index === 4 ? true : false} timeout={{ enter: 1000}} mountOnEnter unmountOnExit>
                            <Typography className={classes.FD_sliderTextContent} variant="body2" component="span">
                                And donate from what We have provided for you before death comes to one of you, and you cry, “My Lord! If only You delayed me for a short while, I would give in charity and be one of the righteous." Al Quran - 63:10
                            </Typography>
                        </Slide>


                        <Typography className={classes.FD_sliderArrow} style={{padding: '0px'}} variant="body2" component="span">
                            <ChevronRightIcon className={classes.material_icons + ' ' +classes.FD_sliderLeftIcons} onClick={this.nextClick } />
                        </Typography>
                    </Grid>

                    {/* <Grid  className={classes.FD_sliderImages}>
                        {index === 0 ? 
                         <Zoom direction="up" in={index === 0 ? true : false} timeout={{ enter: 200, exit: 0}} mountOnEnter unmountOnExit >
                            <Grid item className={classes.FD_activeSliderImge} >
                                <img src={slider1} style={{ borderRadius: '120px', boxShadow: '0px 3px 10px #0000001F', border: '3px solid #FFFFFF', background: 'transparent' }} width='133'  height='133' alt="" />
                                <Typography variant="body" component="figcaption">
                                    <Typography className={classes.FD_figTitle} variant="h5" component="h5">
                                        Imam Siraj WAhhaj
                                    </Typography>
                                    <Typography className={classes.FD_figText} variant="body1" component="p">
                                        Imam, Masjid At-Taqwa, NY
                                    </Typography>
                                </Typography>
                            </Grid>
                            
                        </Zoom>
                            :
                            <div className="Inactive_image">
                             <img src={slider1} className={classes.FD_inactiveSliderImge}  alt="" onClick={() => this.activeIndex(0)}  />
                                <div className={classes.overlay} onClick={() => this.activeIndex(0)} ></div>
                            </div>   
                        }

                        {index === 1 ? 
                        <Zoom direction="up" in={index === 1 ? true : false} timeout={{ enter: 200, exit: 0}} mountOnEnter unmountOnExit >
                        <Grid item className={classes.FD_activeSliderImge} >
                                    <img src={slider2} style={{ borderRadius: '120px', boxShadow: '0px 3px 10px #0000001F', border: '3px solid #FFFFFF', background: 'transparent' }} width='133'  height='133' alt="" />
                                    <Typography variant="body" component="figcaption">
                                        <Typography className={classes.FD_figTitle} variant="h5" component="h5">
                                            Imam Siraj WAhhaj
                                        </Typography>
                                        <Typography className={classes.FD_figText} variant="body1" component="p">
                                            Imam, Masjid At-Taqwa, NY
                                        </Typography>
                                    </Typography>
                                </Grid>
                            
                    </Zoom>
                            :<div className="Inactive_image">
                                <img src={slider2} className={classes.FD_inactiveSliderImge} alt="" onClick={() => this.activeIndex(1)} />
                                <div className={classes.overlay} onClick={() => this.activeIndex(1)}></div>
                            </div>  
                            
                        }

                        {index === 2 ? 
                         <Zoom direction="up" in={index === 2 ? true : false} timeout={{ enter: 200, exit: 0}} mountOnEnter unmountOnExit >
                            <Grid item className={classes.FD_activeSliderImge}>
                                <img src={slider3} style={{ borderRadius: '120px', boxShadow: '0px 3px 10px #0000001F', background: 'transparent' }} width='133' height='133' alt="" />
                                <Typography variant="body" component="figcaption">
                                    <Typography className={classes.FD_figTitle} variant="h5" component="h5">
                                        Imam Siraj WAhhaja
                                    </Typography>
                                    <Typography className={classes.FD_figText} variant="body1" component="p">
                                        Imam, Masjid At-Taqwa, NY
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Zoom>
                            :<div className="Inactive_image">
                             <img src={slider3} className={classes.FD_inactiveSliderImge} alt="" onClick={() => this.activeIndex(2)} />
                            <div className={classes.overlay} onClick={() => this.activeIndex(2)} ></div>
                        </div> 
                        }

                        {index === 3 ? 
                        <Zoom direction="up" in={index === 3 ? true : false} timeout={{ enter: 200, exit: 0}} mountOnEnter unmountOnExit >
                            <Grid item className={classes.FD_activeSliderImge}>
                                <img src={slider4} style={{ borderRadius: '120px', boxShadow: '0px 3px 10px #0000001F', border: '3px solid #FFFFFF', background: 'transparent' }} width='133'  height='133' alt="" />
                                <Typography variant="body" component="figcaption">
                                    <Typography className={classes.FD_figTitle} variant="h5" component="h5">
                                        Imam Siraj WAhhaj
                                    </Typography>
                                    <Typography className={classes.FD_figText} variant="body1" component="p">
                                        Imam, Masjid At-Taqwa, NY
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Zoom>
                            :
                            <div className="Inactive_image">
                            <img src={slider4} className={classes.FD_inactiveSliderImge} alt="" onClick={() => this.activeIndex(3)} />
                            <div className={classes.overlay} onClick={() => this.activeIndex(3)} ></div>
                        </div>  
                        }

                        {index === 4 ? 
                        <Zoom direction="up" in={index === 4 ? true : false} timeout={{ enter: 200, exit: 0}} mountOnEnter unmountOnExit >
                            <Grid item className={classes.FD_activeSliderImge}>
                                <img src={slider5} style={{ borderRadius: '120px', boxShadow: '0px 3px 10px #0000001F', border: '3px solid #FFFFFF', background: 'transparent' }} width='133'  height='133'  alt="" />
                                <Typography variant="body" component="figcaption">
                                    <Typography className={classes.FD_figTitle} variant="h5" component="h5">
                                        Imam Siraj WAhhaj
                                    </Typography>
                                    <Typography className={classes.FD_figText} variant="body1" component="p">
                                        Imam, Masjid At-Taqwa, NY
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Zoom>
                            :
                            <div className="Inactive_image">
                            <img src={slider5} className={classes.FD_inactiveSliderImge} alt="" onClick={() => this.activeIndex(4)} />
                            <div className={classes.overlay} onClick={() => this.activeIndex(4)} ></div>
                        </div>    
                        }

                    </Grid> */}
                
                </Grid>
                </div>
            
            </Grid>
    
        );
    }
}



export default withStyles(styles)(HomeCarousel);
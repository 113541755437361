import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/img/DF_Logo.png';
import Avatar from '@material-ui/core/Avatar';
import personAvatar from '../../assets/img/imagedemo.jpg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';

import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';

import './style.css';
import SuccessAlert from './SuccessAlert';




const useStyles = makeStyles((theme) => ({
  root: {
    width: '100% !important',
    [theme.breakpoints.up('sm')]: {
      height: "96px",
      background: "white",
      padding: "0px 0px",
    },
    [theme.breakpoints.down('sm')]: {
      padding: "0px 20px",
      background: "white",
    }
  },
  main: {
    flexFlow: "row wrap",
    alignItems: "stretch",
  },
  menus: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  appBar: {
    background: "#05CE78",
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  logo: {
    width: '173px',
    '@media (max-width: 600px)': {
      paddingTop: '15px !important',
      margin: "0 auto",
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '15px'
    }
  },
  DF_logoDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    position: 'relative',
    left: '0px',
    '@media (max-width: 600px)': {
      padding: '8px !important',
    },



  },
  personLogo: {
    position: "relative",
    top: "15px"
  },
  bellIcone: {
    padding: "0 40px 0 20px",
    marginTop: '25px'
  },
  moreIcone: {
    marginTop: '25px'
  },
  tabsStyles: {
    width: "auto",
    color: "rgba(0, 0, 0, 0.54)",
  },
  singleTab: {
    height: "96px",
    marginTop: "0px",
    minWidth: "auto",
    padding: "0px 4px 0px 30px",
  },
  bigIndicator: {
    height: 5,
    backgroundColor: "#05CE78",
    marginLeft: "15px",
  },
  activeTextColor: {
    color: "#05CE78"
  },
  Link: {
    textDecoration: 'none',
    color: '#4C5E67',
    fontSize: 14,
    position: "reletive",
  },

  alertMessage: {

  }
}));


const Header = (props) => {
  const [value, setValue] = React.useState(0);

  let active = props.active;
  console.log("Checking active: ", active);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  return (
    <div>

      <div className="success">
        <div className={classes.alertMessage}>
          <Collapse in={open}>
            <Alert
              severity="success"
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Welcome to DEENFUND!  Connecting generous donors with organizations.
                </Alert>
          </Collapse>
        </div>
      </div>

      {/* <div className="header_top_container">

        <div className="main_container">
            <div className="tp_hdr_right">
                ORG ADMIN console - <a className="tp_hdr_link" href="#">SECURE LOGIN</a>
            </div>
            <div className="clearfix"></div>
        </div>        

    </div> */}

      <div className="main_container">
        <Grid container className={classes.root} direction="row" alignItems="stretch" spacing={0} >



          <Grid item md={3} sm={12} xs={12} className={classes.DF_logoDiv} justify="left" >
            <Link to="/" className="logo_link">
              <img src={logo} className="logo" alt="logo" />
            </Link>
          </Grid>

          <Grid item md={9} sm={false} xs={false} >
            <Grid
              className={classes.menus}
              container
              direction="row"
              justify="flex-end"
              alignItems="stretch">
              <Tabs
                className={classes.tabsStyles}
                value={active}
                classes={{ indicator: classes.bigIndicator }}
                // onChange={handleChange}
                aria-label="tab"

              >
                <Link to="/" className={classes.Link}>
                  <Tab className={classes.singleTab + " " + (active === 0 ? classes.activeTextColor : '')} label="Home" />
                </Link>
                <Link to="/about-us" className={classes.Link}>
                  <Tab className={classes.singleTab + " " + (active === 1 ? classes.activeTextColor : '')} label="About Us" />
                </Link>

                <Link to="/about-us#feature" className={classes.Link}>
                  <Tab className={classes.singleTab + " " + (active === 2 ? classes.activeTextColor : '')} label="FEATURES" />
                </Link>

                <Link to="/about-us#how-it-works-for-donors" className={classes.Link}>
                  <Tab className={classes.singleTab + " " + (active === 3 ? classes.activeTextColor : '')} label="HOW IT WORKS" />
                </Link>

              </Tabs>

              {/* <NotificationImportantOutlinedIcon className={classes.bellIcone} />
                <Avatar className={classes.personLogo}  alt="Remy Sharp" src={personAvatar} />
                <ExpandMoreIcon className={classes.moreIcone}  /> */}

            </Grid>


          </Grid>

        </Grid>
      </div>
    </div>
  );
}

export default Header;
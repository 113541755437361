import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';

import personAvatar from '../../assets/img/imagedemo.jpg';

import Drawer from './Drawer';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      display: 'block',
    },
  },
  appBar: {
    background: "#05CE78",
    padding: "4px",
    boxShadow: "none",
    color: "#294C5C",
    display:"block",
    },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar(props) {
  const classes = useStyles();
  const [left, setLeft] = React.useState(false);
  let active  = props.active;


  const toggleDrawer = ( open ) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setLeft(open);
  };

  return (
    <React.Fragment>
      <Drawer  active={active} toggleDrawer={(result) => toggleDrawer( result )} left={left} />
    
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton onClick={toggleDrawer(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon  />
          </IconButton>

           <Typography variant="h6" className={classes.title}>
          </Typography>
          {/*<IconButton edge="start" color="inherit" aria-label="menu">
          <NotificationImportantIcon />
          </IconButton>
          <Avatar alt="Remy Sharp" src={personAvatar} />
          <IconButton edge="end" color="inherit" aria-label="menu">
          <ExpandMoreIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>
      
    </div>
    </React.Fragment>
  );
}

import React from 'react';
import { Grid, Divider, Button, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ReCAPTCHA from "react-google-recaptcha";
import './style.css'
import Axios from 'axios'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GetInquiryTypes } from '../../actions/homeActions';
import SuccessAlert from '../common/SuccessAlert';


const CssTextField = withStyles({
    root: {
        '& .MuiInputBase-root fieldset': {
            border: '1px solid #BFCED7'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#00C9A8',
        },
        '& .MuiFilledInput-underline:after':
        {
            borderBottom: "2px solid #00C9A8"
        },

        //   '& label.Mui-focused': {
        //     color: '#00C9A8',
        //   },
        //   '& .MuiInput-underline:after': {
        //     borderColor: '#00C9A8'
        //   },
        //   '& .MuiFilledInput-underline:after' :{
        //     border: '2px solid #00C9A8'
        //   },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#00C9A8'
            },
        },
    },
})(TextField);


const backendapiURL = process.env.REACT_APP_BACKEND_API_URL;
const TEST_SITE_KEY = process.env.REACT_APP_CAPTCHA_KEY;


class ContactUsForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inquiryTypes: [],
            captcha: '',
            contactData: {
                name: '',
                email: '',
                inquiryType: '',
                message: '',
                stream: process.env.REACT_APP_CONTACT_STREAM,
            },
            userEmail: ''
        }
    }

    componentDidMount() {
        this.props.GetInquiryTypes();
    }

    static getDerivedStateFromProps(props, state) {

        const { inquiryTypes } = props.home;

        let stateChanged = false;
        let changedState = {};


        if (inquiryTypes && JSON.stringify(state.inquiryTypes) !== JSON.stringify(inquiryTypes)) {
            changedState.inquiryTypes = inquiryTypes;
            stateChanged = true;
        }


        if (stateChanged) {
            return changedState;
        }

        return null;
    }

    changeHandler = (e) => {
        if (e.target.name === "message" && e.target.value.length > 100) {
            return;
        } else {
            e.persist();
            this.setState(prevState => ({
                contactData: {
                    ...prevState.contactData,
                    [e.target.name]: e.target.value
                }
            }));
        }
    }

    changeCaptchaHandler = (value) => {
        console.log("captcha ", value)
        this.setState({
            captcha: value
        })
    }



    submitHandler = () => {
        const { name, message, inquiryType, email } = this.state.contactData
        if (name && message && inquiryType && email) {
            let formData = {
                ...this.state.contactData,
                name: {
                    firstName: name?.split(" ")[0],
                    lastName: name?.split(" ")[1]
                },
            }
            Axios
                .post(
                    backendapiURL + '/contact', { ...formData }
                )
                .then(res => {
                    console.log('contact ', res.data);
                    this.setState({
                        captcha: '',
                        userEmail: email,
                        contactData: {
                            name: '',
                            email: '',
                            inquiryType: '',
                            message: '',
                            stream: process.env.REACT_APP_CONTACT_STREAM,
                        },
                    })
                    window.grecaptcha.reset()
                    this.props.showAlert(true, this.state.userEmail)
                    this.props.history.push("/about-us#contact-us")

                })
                .catch(err => console.log('err', err))
        }
    }




    render() {

        const { classes, contactRef } = this.props;

        const { inquiryTypes, contactData, captcha } = this.state;

        const { name, message, inquiryType, email } = contactData;

        let disableBtn = name && message && inquiryType && email && captcha ? false : true;

        // console.log('inqeryTypes', inquiryTypes)

        return (
            <div className="contactForm">
                <Grid id="contact-us" ref={contactRef} direction="column" className={classes.FD_contactform} >
                    <Typography className={classes.FD_aboutTitle} variant="h1" component="h1">
                        HOW CAN WE HELP?
                    <Divider className={classes.FD_line} />
                    </Typography>
                    <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                        We’re very approachable and would love to help you with your queries.
                </Typography>

                    <Grid container alignItems="center" direction="row" className={classes.FD_DonorMenuSection}>

                        <Grid container md={12} sm={12} xs={12} direction="row" spacing={3}  >

                            <Grid item md={6} className={classes.FD_contactFormWidth}>

                                <CssTextField
                                    fullWidth
                                    //helperText="Helper Text!"
                                    label="Full Name"
                                    variant="outlined"
                                    name="name"
                                    onChange={this.changeHandler}
                                    value={contactData.name}
                                />
                            </Grid>

                            <Grid item md={6} className={classes.FD_contactFormWidth}>
                                <CssTextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    name="email"
                                    onChange={this.changeHandler}
                                    value={contactData.email}
                                />
                            </Grid>
                            <Grid item md={12} className={classes.FD_contactFormWidth}>
                                <CssTextField
                                    fullWidth
                                    label="Inquiry Type"
                                    variant="filled"
                                    className="textfieldmenu"
                                    select
                                    helperText="Please select Inquiry Types"
                                    name="inquiryType"
                                    onChange={this.changeHandler}
                                    value={contactData.inquiryType}
                                >
                                    {inquiryTypes && inquiryTypes.map((type, index) => (
                                        <MenuItem key={index} value={type.inquiry}>
                                            {type.inquiry}
                                        </MenuItem>
                                    ))}

                                </CssTextField>

                            </Grid>

                            <Grid item md={12} className={classes.FD_contactFormWidth}>

                                <CssTextField
                                    fullWidth
                                    multiline
                                    rows={8}
                                    style={{ height: '130px !important' }}
                                    variant="outlined"
                                    label="Please enter your query here"
                                    name="message"
                                    //helperText="Helper Text!"
                                    onChange={this.changeHandler}
                                    value={contactData.message}

                                />
                                <p className={classes.charLength}>CHAR {contactData.message.length}/100</p>
                            </Grid>

                            <Grid container md={12} justify="center" direction="row" alignItems="center" className={classes.FD_captcha}>
                                {/* <Captcha /> */}
                                <ReCAPTCHA
                                    sitekey={TEST_SITE_KEY}
                                    onChange={this.changeCaptchaHandler}
                                />
                            </Grid>

                            <Button ariant="contained" className={`${classes.FD_signUpNow} ${disableBtn ? classes.disableButton : ''}`} onClick={this.submitHandler} disabled={disableBtn}>
                                Send message
                            </Button>

                        </Grid>
                    </Grid>

                </Grid>
            </div>

        )
    }
}

ContactUsForm.propTypes = {
    GetInquiryTypes: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    home: state.home
});

export default connect(mapStateToProps, { GetInquiryTypes })(ContactUsForm);

import React, {useState} from 'react'
import RichTextExample from './Editor'

export default function EditorComponent() {
    const [value, setValue] = useState([{type:'paragraph',children:[{text:"Lorem Ipsum is simply dummy text of the printing and typesetting industry."}]}]);

    const onChange = (e) => {
        console.log(e)
        setValue(e)
    };

    return (
        <RichTextExample 
            onChange={(e) => onChange(e)}
            disabled={false} 
            value={value} 
            label={"Description Text"}
            helperText={"Helper Text"}
            maxCharCount={200}
        />
    )
}

import axios from 'axios';

import {
    INQUIRIES,
} from './types';

let backendServerURL = process.env.REACT_APP_API_URL;



export const GetInquiryTypes = () => dispatch => {

    axios
        .get(
            backendServerURL + '/api/inquiryTypes.json'
        )
        .then(res => {
            console.log('INQUIRY', res.data);
            dispatch({ type: INQUIRIES, payload: res.data })
        })
        .catch(err => console.log('err', err))
};


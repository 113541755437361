import React, { createRef, useEffect } from 'react';
import Banner from '../common/about_banner';
import AppBar from '../common/AppBar.js';
import Header from '../common/Header';
import Footer from '../common/footer';
import LowerFooter from '../common/lower-footer';

import { Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Icon1 from '../../assets/img/icon_1.png';
import Icon2 from '../../assets/img/icon_2.png';
import Icon3 from '../../assets/img/icon_3.png';
import Icon4 from '../../assets/img/icon_4.png';
import Icon5 from '../../assets/img/icon_5.png';
import Icon6 from '../../assets/img/icon_6.png';
import PointingArrow from '../../assets/img/pointing_arrow.png';
import featureDownArrow from '../../assets/img/pointing_arrow_bottom.png';
import amount from '../../assets/img/ammount_img.png';
import org from '../../assets/img/org_img.png';
import donation from '../../assets/img/donation_img.png';
import others from '../../assets/img/others_img.png';
import percentage_bg from '../../assets/img/percentage_bg.png';
import TextField from '@material-ui/core/TextField';
import FAQS from './faq';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Link, withRouter } from 'react-router-dom';
import './style.css'
import ContactUsForm from './ContactUsForm';
import SuccessAlert from '../common/SuccessAlert';




const useStyles = makeStyles((theme) => ({
    FD_pagePart: {
        background: "#05CE78",
        textAlign: "center",
        height: "52px",
        '@media (max-width:600px)': {
            height: 'auto'
        }
    },
    FD_padding_zero: {
        padding: "0 !important"
    },
    tabsStyles: {
        width: "auto",
        color: "white",
        fontWeight: 500
    },
    singleTab: {
        height: "52px",
        marginTop: "0px",
        minWidth: "auto",
        padding: "0px 24px 0px 24px",
        opacity: 'inherit',
        '@media (max-width:600px)': {
            display: 'block',
            margin: '0 auto',
            maxWidth: 'inherit',
        }
    },
    bigIndicator: {
        height: 5,
        backgroundColor: "white",
        '@media (max-width:600px)': {
            display: 'none'
        },
    },
    activeTextColor: {
        color: "white",
        fontWeight: "bold",
        '@media (max-width:600px)': {
            borderBottom: '5px solid white'
        },
    },
    inner_Body: {
        padding: "24px",
        [theme.breakpoints.down('xs')]: {
            padding: "5px",
        },
        '@media(max-width:425px)': {
            justifyContent: 'center',
            padding: '10px'
        }

    },
    FD_aboutTitle: {
        textAlign: "center",
        color: "#294C5C",
        fontSize: "36px",
        fontWeight: 600,
        margin: "0px 0 5px 0",
        '@media(max-width:425px)': {
            fontSize: '28px',
        }
    },
    FD_linkColor: {
        color: "#05CE78",
        textDecoration: "none"
    },
    FD_line: {
        background: "#BFCED7",
        height: "2px",
        maxWidth: "100px",
        borderRadius: "4px",
        margin: "15px auto",
    },
    FD_aboutPara: {
        textAlign: "center",
        // padding: "0 20px",
        color: "#4C5E67 !important",
        fontSize: "22px !important",
        opacity: 0.8,
        fontFamily: 'Open Sans',
        lineHeight: "1.5 !important",
        letterSpacing: '1px',

        [theme.breakpoints.down('md')]: {
            padding: "0 5px",
        },
        '@media (max-width: 1279.95px)': {
            padding: "0 10px",
        },
        '@media(max-width:425px)': {
            fontSize: '18px !important',
        }

    },
    FD_aboutSection: {
        background: "#f9f9f9",
        padding: "60px 0",
    },
    FD_aboutTextSection: {
        maxWidth: "945px",
        margin: "0 auto",
        padding: "0 20px",
    },
    FD_aboutText: {
        fontSize: "18px",
        color: "#4C5E67",
        opacity: 0.8,
        letterSpacing: "0.18px",
        lineHeight: '32px',
        margin: "18px 0px",
        fontFamily: "Open Sans",

    },
    FD_aboutLink: {
        fontWeight: 400,
    },
    FD_featureSection: {
        maxWidth: "1140px",
        margin: "0 auto",
        padding: "50px 0"
    },
    faqSection: {
        margin: "0 auto",
        padding: "50px 0",
        backgroundColor: '#f9f9f9',
    },
    featuredGird: {
        padding: '24px',
        '@media(max-width:425px)': {
            justifyContent: 'center',
        }
    },
    FD_featureImg: {
        width: "65px",
    },
    FD_FeaturesPoint: {
        textAlign: "center"
    },
    FD_PointTitle: {
        color: "#294C5C",
        fontWeight: "bold",
        fontSize: "22px",
        margin: "18px 0",
        fontFamily: "Open Sans",

    },
    FD_PointTitle2: {
        color: "#294C5C",
        fontWeight: "bold",
        fontSize: "18px",
        margin: "18px 0",
        fontFamily: "Open Sans",

    },
    FD_PointText: {
        color: "#4C5E67",
        opacity: 0.8,
        fontSize: "18px",
        fontFamily: "Open Sans",
        lineHeight: '28px'
    },
    FD_PointText2: {
        color: "#4C5E67",
        opacity: 0.8,
        fontSize: "18px",
        fontFamily: "Open Sans",
        lineHeight: '28px',
        '@media(max-width:1200px)': {

        },

    },
    FD_DonorSection: {
        background: "#f9f9f9",
        padding: "50px 0"
    },
    FD_DonorMenuSection: {
        maxWidth: "1364px !important",
        width: '100%',
        margin: "0 auto",
        marginTop: "50px !important",
    },

    FD_DonorMenuSection_inner: {
        maxWidth: "1364px !important",
    },

    FD_DonorPart: {
        background: "#fff",
        borderRadius: "12px",
        margin: '0px 12px',
        padding: "15px !important",
        boxShadow: "0 4px 18px 0 rgba(0,0,0,.12), 0 7px 10px -5px rgba(0,0,0,.15)",
        textAlign: "center",
        height: "460px",
        maxWidth: '22%',
        [theme.breakpoints.down('sm')]: {
            height: "380px !important",
            maxWidth: "46% !important",
            paddingTop: '15px !important',
            width: "auto",
            marginBottom: '10px'
        },
        [theme.breakpoints.down('xs')]: {
            height: "380px !important",
            width: "auto",
            maxWidth: "100% !important"

        },
        '@media(max-width:400px)': {
            height: 'auto',
        }
    },
    FD_featureArrowImg: {
        position: "absolute",
        marginLeft: "218px",
        marginTop: "-3px",
        [theme.breakpoints.down('lg')]: {
            marginLeft: "193px",
            marginTop: "2px",
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    FD_featureArrowImg2: {
        position: "absolute",
        marginLeft: "218px",
        marginTop: "-15px",
        [theme.breakpoints.down('lg')]: {
            marginLeft: "193px",
            marginTop: "-14px",
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    FD_secondDonor: {
        marginTop: "52px",
        height: "470px !important",
        [theme.breakpoints.down('sm')]: {
            marginTop: "0px",
            height: "380px !important",
            marginBottom: '10px',
        },
        '@media(max-width:400px)': {
            height: 'auto',
        }
    },
    FD_featureDownArrowImg: {
        position: "absolute",
        marginLeft: "146px",
        marginTop: "38px",
        [theme.breakpoints.down('lg')]: {
            marginLeft: "136px",
            marginTop: "-26px",
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    FD_featureDownArrowImg2: {
        position: "absolute",
        marginLeft: "146px",
        marginTop: "65px",
        [theme.breakpoints.down('lg')]: {
            marginLeft: "136px",
            marginTop: "36px",
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    FD_signUpNow: {
        background: "#05CE78 !important",
        color: "#fff !important",
        width: "225px",
        height: "50px",
        display: "block",
        margin: "45px auto 0",
        fontWeight: 600,
        letterSpacing: "0.90px",

    },
    FD_adSection: {
        background: "#B8B8B8",
        opacity: 0.45,
        paddingTop: "70px",
        paddingBottom: "70px",
    },
    FD_adTitle: {
        fontSize: "64px",
        textAlign: "center",
        fontWeight: "bold",
    },
    FD_percentage: {
        margin: "96px auto",
        maxWidth: "1140px",
        textAlign: "center",
    },
    FD_percentageTitle: {
        color: "#294C5C",
        fontSize: "36px",
        fontWeight: 400,
    },
    FD_percentageText: {
        fontFamily: "Open Sans",
        color: "#4C5E67",
        opacity: 0.8,
        lineHeight: 1.5,
        fontSize: "22px",
    },
    sws_work: {
        padding: "30px",
        boxShadow: "0 4px 18px 0 rgba(0,0,0,.12), 0 7px 10px -5px rgba(0,0,0,.15)",
        borderRadius: "12px",
        width: "100%",
        position: "relative",
        margin: "0 auto",
        marginTop: "50px !important",
        left: "6%",
        [theme.breakpoints.down('sm')]: {
            padding: "0px",
        },
        '@media (max-width:1500px)': {
            width: "85%",
            position: "relative",
        },

        '@media (max-width:960px)': {
            width: "100%",
            position: "relative",
            left: "0%",
        },

    },
    percentage: {
        background: `url(${percentage_bg})`,
        width: "289px",
        height: "310px",
        top: "10px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "300px",
        paddingTop: "90px",
        float: "left",
        [theme.breakpoints.down('sm')]: {
            background: "#294C5C",
            width: "100%",
            height: "auto",
            left: 0,
            top: 0,
            marginLeft: "0px",
            alignItem: "center",
            backgroundRepeat: "no-repeat",
            paddingTop: 0,
            borderRadius: "4px",
            marginTop: "auto"
        }
    },
    percentage2: {
        '@media (min-width:960px)': {
            left: "-138px",
            position: "absolute",
            paddingTop: "40px !important",
            top: "13px",
        },

    },
    prc_col: {
        marginLeft: "13px",
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            paddingTop: "30px",
        }
    },
    percentage_span: {
        fontSize: "110px",
        fontStyle: "italic",
        color: "#fff",
        fontWeight: "bold",
        fontFamily: 'Open Sans',
        position: "relative",
    },
    percentage_span_label: {
        fontSize: "50px",
        fontFamily: 'Open Sans',
        position: "absolute",
        top: "26px",
        marginLeft: "10px",
        fontWeight: 900,
        color: "#fff",
        [theme.breakpoints.down('sm')]: {
            top: '0px'
        },
        '@media (min-width:960px)': {
            marginLeft: "120px",
        },
    },
    percentage_h3: {
        fontSize: "18px",
        color: "#fff",
        fontWeight: "bold",
        '@media (min-width:768px)': {
            width: '230px',
            textAlign: "center",
            margin: "0 auto",
        },
    },
    percentage_span_p: {
        fontSize: "21px",
        fontStyle: "italic",
        color: "#fff",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
    },
    sws_cont: {
        marginLeft: "100px !important",
        textAlign: "left",
        '@media (min-width:960px)': {
            marginLeft: "100px !important",
            textAlign: "left",

        },

        '@media (max-width:960px)': {
            marginLeft: "100px !important",
            textAlign: "left",
            marginLeft: "0 !important",
            padding: "50px 15px",

        },
    },
    sws_cont_p: {
        fontSize: "20px",
        fontWeight: 400,
        color: "#6f7d84",
        lineHeight: "30px",
        fontFamily: "Open Sans, sans-serif",
    },
    FD_ProcessTitle: {
        color: "#294C5C",
        fontSize: '22px',
        fontWeight: 'bold',
        margin: '20px 0px'
    },
    FD_contactform: {
        maxWidth: "1140px",
        margin: "0 auto",
        padding: "70px 20px",
    },
    FD_contactFormWidth: {
        width: "100%",
    },
    textField: {
        color: "blue",
        '& .MuiInputBase-root fieldset': {
            border: 'unset !important'
        },
    },
    cssOutlinedInput: {
        borderColor: 'blue !important',
    },
    cssFocused: {},

    notchedOutline: {
        borderWidth: '1px !important',
        borderColor: 'purple !important'
    },
    formControl: {
        backgroundColor: "yellow",
        borderBottomColor: "rgba(0, 0, 0, .42)",
    },
    selectField: {
        backgroundColor: "#f5f5f5 !important"
    },
    FD_captcha: {
        textAlign: "center",
    },
    FD_captcha_btn: {
        width: "70%;",
        height: "50px",
        backgroundColor: "transparent !important",
        border: "1px solid gray",
        color: "gray !important",
    },
    tabContainer: {
        display: 'block'
    },
    FD_seeMore: {
        background: "#05CE78 !important",
        color: "#fff !important",
        width: "225px",
        justifyContent: 'center',
        height: "50px",
        display: "flex",
        margin: "25px auto 0",
        fontWeight: "600",
    },
    Link: {
        textDecoration: 'none',
        color: 'inherit'
    },
    charLength: {
        position: 'relative',
        top: '0px',
        float: 'right',
        color: '#BFCED7',
        letterSpacing: '0.4px',
        fontSize: '12px',
        fontFamily: 'Roboto',
    },
    disableButton: {
        border: "1px solid #999999 !important",
        backgroundColor: "#cccccc !important",
        color: "#666666 !important",
    }


}));

const CssTextField = withStyles({
    root: {
        '& .MuiInputBase-root fieldset': {
            border: '1px solid #BFCED7'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#00C9A8',
        },
        '& .MuiFilledInput-underline:after':
        {
            borderBottom: "2px solid #00C9A8"
        },

        //   '& label.Mui-focused': {
        //     color: '#00C9A8',
        //   },
        //   '& .MuiInput-underline:after': {
        //     borderColor: '#00C9A8'
        //   },
        //   '& .MuiFilledInput-underline:after' :{
        //     border: '2px solid #00C9A8'
        //   },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#00C9A8'
            },
        },
    },
})(TextField);




const AboutUs = (props) => {
    const [value, setValue] = React.useState(0);
    const aboutRef = createRef();
    const featuredRef = createRef();
    const hwoItWorksRef = createRef();
    const pricingRef = createRef();
    const faqsRef = createRef();
    const contactRef = createRef();
    const howItWorksOrgRef = createRef();

    useEffect(() => {

        if (window.location.href.indexOf("about-us#about") > -1) {
            setValue(0);
            aboutRef.current.scrollIntoView({ behavior: "smooth" });
        }
        else if (window.location.href.indexOf("about-us#feature") > -1) {
            setValue(1);
            featuredRef.current.scrollIntoView({ behavior: "smooth" });
        }
        else if (window.location.href.indexOf("about-us#pricing") > -1) {
            setValue(3);
            pricingRef.current.scrollIntoView({ behavior: "smooth" });
        }
        else if (window.location.href.indexOf("about-us#faq") > -1) {
            setValue(4);
            faqsRef.current.scrollIntoView({ behavior: "smooth" });
        }
        else if (window.location.href.indexOf("about-us#contact-us") > -1) {
            setValue(3);
            contactRef.current.scrollIntoView({ behavior: "smooth" });
        }
        else if (window.location.href.indexOf("about-us#how-it-works-for-donors") > -1) {
            setValue(2);
            hwoItWorksRef.current.scrollIntoView({ behavior: "smooth" });
        }
        else if (window.location.href.indexOf("about-us#how-it-works-for-orgs") > -1) {
            setValue(2);
            howItWorksOrgRef.current.scrollIntoView({ behavior: "smooth" });
        }
        else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            aboutRef.current.scrollIntoView({ behavior: "smooth" });
        }
        else if (newValue === 1) {
            featuredRef.current.scrollIntoView({ behavior: "smooth" });
        }
        else if (newValue === 2) {
            hwoItWorksRef.current.scrollIntoView({ behavior: "smooth" });
        }
        else if (newValue === 3) {
            pricingRef.current.scrollIntoView({ behavior: "smooth" });
        }
        else if (newValue === 4) {
            faqsRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState("");

    const showAlert = (value, email) => {
        setOpen(value)
        setEmail(email)

        setTimeout(() => {
            setOpen(false)
        }, 5000);
    }



    return (
        <React.Fragment>

            <SuccessAlert msg={`Thank You For Contacting Us! You will recieve an email confirmation shortly at ${email}`} open={open} setOpen={setOpen} />


            <AppBar active={1} />
            <Header active={1} />

            <Banner />

            <Grid className={classes.FD_pagePart} >
                <Grid container className={classes.FD_padding_zero} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >

                        <Grid container xs={12} justify="center" >
                            <Tabs
                                className={classes.tabsStyles}
                                value={value}
                                classes={{
                                    indicator: classes.bigIndicator,
                                    flexContainer: classes.tabContainer
                                }}
                                onChange={handleChange}
                                aria-label="tab"
                            >

                                <Link to="/about-us#about" className={classes.Link}>
                                    <Tab className={classes.singleTab + " " + (value === 0 ? classes.activeTextColor : '')} label="ABOUT US" />
                                </Link>
                                <Link to="/about-us#feature" className={classes.Link}>
                                    <Tab className={classes.singleTab + " " + (value === 1 ? classes.activeTextColor : '')} label="FEATURES" />
                                </Link>
                                <Link to="/about-us#how-it-works-for-donors" className={classes.Link}>
                                    <Tab className={classes.singleTab + " " + (value === 2 ? classes.activeTextColor : '')} label="HOW IT WORKS" />
                                </Link>
                                <Link to="/about-us#pricing" className={classes.Link}>
                                    <Tab className={classes.singleTab + " " + (value === 3 ? classes.activeTextColor : '')} label="PRICING" />
                                </Link>
                                <Link to="/about-us#faq" className={classes.Link}>
                                    <Tab className={classes.singleTab + " " + (value === 4 ? classes.activeTextColor : '')} label="FAQS" />
                                </Link>
                            </Tabs>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

            <Grid id='about' ref={aboutRef} className={classes.FD_aboutSection} >
                <Grid container className={classes.inner_Body} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                        <Grid container xs={12} direction="column" className={classes.FD_aboutTextSection} >
                            <Typography className={classes.FD_aboutTitle} variant="h1" component="h1">
                                ABOUT DEENFUND
                                    <Divider className={classes.FD_line} />
                            </Typography>
                            <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                                We are a global platform connecting awesome donors
                                </Typography>
                            <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                                to amazing organizations with great causes
                                </Typography>
                            <Typography className={classes.FD_aboutText} variant="body1" component="p" style={{ paddingTop: '32px' }}>
                                Masa’jids are the epicenter for the establishment of a Muslim community. It is the place where we worship our Lord, seek peace, meet our friends, send our kids to learn, get help, find advice, and get married. Our Masa’jids work very hard to fulfill our needs in the community through their efforts and through donations. Muslims had a significant role in the evolvement of society. On the onset of Islam, we have had an impact on society at large from building communities up, like Ahmad ibn Tulun who built a Hospital in 872 in Egypt, to educating members of the society, like Fatima al-Firhi and her sister, Miriam al-Firhi, who opened up the very first University adjacent to Al-Qarawiyyin Mosque in Fez, Morocco.
                                </Typography>
                            <Typography className={classes.FD_aboutText} variant="body1" component="p" >
                                DEENFUND is connecting individuals and communities throughout the globe to help and support each other for a better future. Donors can maximize their deeds for the maximum reward by supporting their individual community or communities altogether with just a few clicks. Organizations can raise awareness and collect donations easily without taking valuable time out of their day.
                                </Typography>

                            <Typography className={classes.FD_aboutText} variant="body1" component="p" >
                                It is time we help create and continue our legacy in our communities for a better, brighter tomorrow! DEENFUND is the bridge bringing our communities and members of society together for a bigger cause.
                                </Typography>
                            {/* <Link to="#" className={classes.FD_linkColor+" "+classes.FD_aboutLink} style={{fontSize: '14px', fontFamily: 'roboto'}}>CONTINUE READING...</Link>*/}
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

            <Grid container id='feature' ref={featuredRef} xs={12} direction="column" className={classes.FD_featureSection} >
                <Typography className={classes.FD_aboutTitle} variant="h1" component="h1">
                    DEENFUND FEATURES
                        <Divider className={classes.FD_line} />
                </Typography>
                <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                    We are committed to provide an easy, effective and secured platform for donors and
                    </Typography>
                <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                    organizations. Here are few of our awesome services.
                    </Typography>

                <Grid container xs={12} alignItems="center" direction="row" className={classes.featuredGird}>
                    <Grid container xs={12} spacing={3} >
                        <Grid item className={classes.FD_FeaturesPoint} md={4} sm={6} xs={12}>
                            <img className={classes.FD_featureImg} src={Icon1} alt="" />
                            <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                LESS WORK MORE DAWAH
                                </Typography>
                            <Typography className={classes.FD_PointText} variant="body1" component="p">
                                Our platform handles complete donation process. That leaves more time for you to focus on more dawah work, share DEENFUND with others and earn more hasanah.
                                </Typography>
                        </Grid>
                        <Grid item className={classes.FD_FeaturesPoint} md={4} sm={6} xs={12}>
                            <img className={classes.FD_featureImg} src={Icon2} alt="" />
                            <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                SAFE & SECURE
                                </Typography>
                            <Typography className={classes.FD_PointText} variant="body1" component="p">
                                We've partnered with well-reputed payment processing company Stripe to process your donations in most secured way and complaint with all regulations.
                                </Typography>
                        </Grid>
                        <Grid item className={classes.FD_FeaturesPoint} md={4} sm={6} xs={12}>
                            <img className={classes.FD_featureImg} src={Icon3} alt="" />
                            <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                EASY & FAST
                                </Typography>
                            <Typography className={classes.FD_PointText} variant="body1" component="p">
                                First impression is the best impression. We spent tons of hours to built the most user-friendly and easy to use platform for both donors and organizations for remarkable donation experience, ever.
                                </Typography>
                        </Grid>
                        <Grid item className={classes.FD_FeaturesPoint} md={4} sm={6} xs={12}>
                            <img className={classes.FD_featureImg} src={Icon4} alt="" />
                            <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                TRANSPARENCY
                                </Typography>
                            <Typography className={classes.FD_PointText} variant="body1" component="p">
                                It's simple. There's nothing hidden. You will be able view your donations, donation history, change donation period and much more from your account.
                                </Typography>
                        </Grid>
                        <Grid item className={classes.FD_FeaturesPoint} md={4} sm={6} xs={12}>
                            <img className={classes.FD_featureImg} src={Icon5} alt="" />
                            <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                TAX EXEMPTION
                                </Typography>
                            <Typography className={classes.FD_PointText} variant="body1" component="p">
                                With simple steps, you will be able to request for Tax Exemption Certificate from the organizations you donated generously at any point of time.
                                </Typography>
                        </Grid>
                        <Grid item className={classes.FD_FeaturesPoint} md={4} sm={6} xs={12}>
                            <img className={classes.FD_featureImg} src={Icon6} alt="" />
                            <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                GREAT REWARD
                                </Typography>
                            <Typography className={classes.FD_PointText} variant="body1" component="p">
                                Charity does not in any way decrease the wealth, rather giving regular charity out of what we have, purifies our wealth, and provide share on the Day or Resurrection [At-Tirmidhi]
                                </Typography>
                        </Grid>

                    </Grid>



                </Grid>
            </Grid>

            <Grid xs={12} id='how-it-works-for-donor' ref={hwoItWorksRef} direction="column" className={classes.FD_DonorSection} >
                <div className="main_container_large">
                    <Typography className={classes.FD_aboutTitle} variant="h1" component="h1">
                        HOW IT WORKS FOR DONOR
                        <Divider className={classes.FD_line} />
                    </Typography>
                    <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                        Our donation platform is here to guide your every step of the way and make
                    </Typography>
                    <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                        your donation process easy and simple
                    </Typography>

                    <Grid xs={12} alignItems="center" direction="row" className={classes.FD_DonorMenuSection}>

                        <Grid container xs={12} direction="row" spacing={2} className={classes.FD_DonorMenuSection_inner}>

                            <Grid item className={classes.FD_DonorPart} lg={3} md={6} sm={12} xs={12}>
                                <img className={classes.FD_featureArrowImg} src={PointingArrow} alt="" />
                                <img src={amount} alt="" style={{ marginTop: "15px" }} />
                                <Typography className={classes.FD_PointTitle2} variant="h3" component="h3">
                                    CHOOSE DONATION AMOUNT
                                </Typography>
                                <Typography className={classes.FD_PointText2} variant="body1" component="p">
                                    Either from online, mobile or on-site in an organization, simply select or enter a specific one-time or recurring amount you want to donate to the choice of your organization.
                                </Typography>
                            </Grid>

                            <Grid item className={classes.FD_DonorPart + " " + classes.FD_secondDonor} lg={3} md={6} sm={12} xs={12}>
                                <img src={org} alt="" style={{ marginTop: "15px" }} />
                                <Typography className={classes.FD_PointTitle2} variant="h3" component="h3">
                                    SEARCH ORGANIZATION
                                </Typography>
                                <Typography className={classes.FD_PointText2} variant="body1" component="p">
                                    Select the choice of your organizations to receive your generous donations. And if you are not able to find the organization, please introduce DEENFUND to the organization’s team members.
                                </Typography>
                                <img className={classes.FD_featureDownArrowImg} src={featureDownArrow} alt="" />

                            </Grid>

                            <Grid item className={classes.FD_DonorPart} lg={3} md={6} sm={12} xs={12}>
                                <img className={classes.FD_featureArrowImg} src={PointingArrow} alt="" />
                                <img src={donation} alt="" style={{ marginTop: "15px" }} />
                                <Typography className={classes.FD_PointTitle2} variant="h3" component="h3">
                                    SUBMIT DONATIONS
                                </Typography>
                                <Typography className={classes.FD_PointText2} variant="body1" component="p">
                                    We’ve partnered with secured donation processing platform Stripe. Enter your Credit or Debit Card details, and submit your donations.
                                </Typography>
                            </Grid>

                            <Grid item className={classes.FD_DonorPart + " " + classes.FD_secondDonor} lg={3} md={6} sm={12} xs={12}>
                                <img src={others} alt="" style={{ marginTop: "15px" }} />
                                <Typography className={classes.FD_PointTitle2} variant="h3" component="h3">
                                    SHARE & MOTIVATE OTHERS
                                </Typography>
                                <Typography className={classes.FD_PointText2} variant="body1" component="p">
                                    Let others get motivated from your contribution. Share the awesome news with your friends and families and earn more hasanah.
                                </Typography>

                            </Grid>
                        </Grid>
                        {/*<Button ariant="contained" className={classes.FD_signUpNow} >
                            SIGN UP NOW
                            </Button>*/}
                    </Grid>
                </div>
            </Grid>

            <Grid xs={12} ref={howItWorksOrgRef} id='how-it-works-for-orgs' direction="column" className={classes.FD_DonorSection} >
                <div className="main_container_large">
                    <Typography className={classes.FD_aboutTitle} variant="h1" component="h1">
                        HOW IT WORKS FOR ORGANIZATION
                        <Divider className={classes.FD_line} />
                    </Typography>
                    <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                        Our donation platform is here to guide your every step of the way and make
                    </Typography>
                    <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                        your donation process easy and simple
                    </Typography>

                    <Grid xs={12} alignItems="center" spacing={3} direction="row" className={classes.FD_DonorMenuSection}>

                        <Grid container xs={12} direction="row" spacing={3} className={classes.FD_DonorMenuSection_inner}>

                            <Grid item className={classes.FD_DonorPart} md={3} sm={6} xs={12}>
                                <img className={classes.FD_featureArrowImg2} src={PointingArrow} alt="" />
                                <img src={amount} alt="" />
                                <Typography className={classes.FD_PointTitle2} variant="h3" component="h3">
                                    VERIFY YOUR ORGANIZATION
                                </Typography>
                                <Typography className={classes.FD_PointText2} variant="body1" component="p">
                                    Get started with the easiest, simple online platform for collecting donations. Make donations a pleasant experience for your organization and for your generous donors.
                                </Typography>
                            </Grid>

                            <Grid item className={classes.FD_DonorPart + " " + classes.FD_secondDonor} md={3} sm={6} xs={12}>
                                <img src={org} alt="" />
                                <Typography className={classes.FD_PointTitle2} variant="h3" component="h3">
                                    LINK ORG. BANK ACCOUNT
                                </Typography>
                                <Typography className={classes.FD_PointText2} variant="body1" component="p">
                                    We’ve partnered with secured donation processing platform Stripe. Connect your bank account to start collecting donations from amazing donors.
                                </Typography>
                                <img className={classes.FD_featureDownArrowImg2} src={featureDownArrow} alt="" />

                            </Grid>

                            <Grid item className={classes.FD_DonorPart} md={3} sm={6} xs={12}>
                                <img className={classes.FD_featureArrowImg2} src={PointingArrow} alt="" />
                                <img src={donation} alt="" />
                                <Typography className={classes.FD_PointTitle2} variant="h3" component="h3">
                                    COLLECT DONATIONS
                                </Typography>
                                <Typography className={classes.FD_PointText2} variant="body1" component="p">
                                    Start collecting one-time and monthly donations from online or from your website with custom buttons - directly on every single page of your website and your own embedded donation form.
                                </Typography>
                            </Grid>

                            <Grid item className={classes.FD_DonorPart + " " + classes.FD_secondDonor} md={3} sm={6} xs={12}>
                                <img src={others} alt="" />
                                <Typography className={classes.FD_PointTitle2} variant="h3" component="h3">
                                    THANK YOUR DONORS
                                </Typography>
                                <Typography className={classes.FD_PointText2} variant="body1" component="p">
                                    To show appreciation to your donors, we provide Thank You notes, which you can find on your Dashboard. You can easily leave a personal note to express your gratitude for each and every donation you receive.
                                </Typography>

                            </Grid>
                            {/*<Button ariant="contained" className={classes.FD_signUpNow} >
                            SIGN UP NOW
                        </Button>*/}

                        </Grid>
                    </Grid>
                </div>
            </Grid>


            {/* <Grid container xs={12} direction="column" className={classes.FD_adSection} >
                    <Typography className={classes.FD_adTitle} variant="h1" component="h1">
                        AD SPACE
                    </Typography>
            </Grid> */}
            <div className="main_container">
                <Grid xs={12} id='pricing' ref={pricingRef} direction="column" className={classes.FD_percentage} >
                    <Typography className={classes.FD_percentageTitle} variant="h1" component="h1">
                        DO MORE AWESOME WORK WITH
                    </Typography>
                    <Typography className={classes.FD_percentageTitle} variant="body1" component="p">
                        <b>SIMPLE &amp; LESS FEE</b>
                        <Divider className={classes.FD_line} />
                    </Typography>
                    <Typography className={classes.FD_percentageText} variant="body1" component="p">
                        We are global platform connecting awesome donors to amazing organizations with great causes.
                    </Typography>
                    <Typography className={classes.FD_percentageText} style={{ paddingBottom: '10px' }} variant="body1" component="p">
                        organizations with great causes.
                    </Typography>

                    <Grid container xs={12} alignItems="center" spacing={3} direction="row" className={classes.sws_work}>

                        <Grid container className={classes.percentage + ' ' + classes.percentage2} md={4} sm={12} xs={12} direction="row" spacing={3} style={{ padding: '24px' }} >
                            <Grid item className={classes.prc_col} md={3} sm={12} xs={12}>

                                <Typography className={classes.percentage_span} variant="body2" component="span">70
                                <Typography variant="body1" component="label" className={classes.percentage_span_label}>% <Typography variant="body1" className={classes.percentage_span_p} component="p">LESS</Typography></Typography>
                                </Typography>
                                <Typography variant="h3" component="h3" className={classes.percentage_h3} >Than Most Platforms</Typography>

                            </Grid>
                        </Grid>
                        <Grid container className={classes.sws_cont} md={12} sm={12} xs={12} direction="row" spacing={3}>
                            <Grid item >

                                <Typography variant="body1" className={classes.sws_cont_p} component="p">
                                    We are committed in providing the best of the donation experience with very minimal operational cost. Most online fundraising and donation sites charges 5% - 10% for using their platforms. <b>We charge 1.75%, which is in average 70% less</b> than most platforms.
                            </Typography>

                                <Typography className={classes.FD_ProcessTitle} variant="h2" component="h2" >
                                    Donation Processor Cost
                            </Typography>

                                <Typography variant="body1" className={classes.sws_cont_p} component="p">
                                    All online donations requires a third-party credit card processor such as PayPal,WePay or Stripe. We have partnered up with Stripe, a well-reputed and secured platform that charges <b>2.9% plus $0.30 per transaction. This is not a DEENFUND fee.</b>
                                </Typography>

                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </div>


            <Grid ref={faqsRef} id='faq' xs={12} direction="column" className={classes.faqSection} >
                <div className="main_container">
                    <Typography className={classes.FD_aboutTitle} variant="h1" component="h1">
                        FREQUENTLY ASKED QUESTIONS
                        <Divider className={classes.FD_line} />
                    </Typography>
                    <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                        Our platform is simple and easy to use for both donors and organizations. However,
                    </Typography>
                    <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                        here we’ve answered some queries that we feel will help you better.
                    </Typography>

                    <Grid container xs={12} alignItems="center" direction="row" className={classes.featuredGird}>
                        <FAQS />
                    </Grid>
                </div>
                {/*<Button ariant="contained" className={classes.FD_seeMore} >
                            See More <KeyboardArrowRightIcon />
                    </Button>*/}
            </Grid>

            <ContactUsForm classes={classes} contactRef={contactRef} showAlert={showAlert} history={props.history} />


            <Footer />
            <LowerFooter />

        </React.Fragment>

    );
}

export default withRouter(AboutUs);
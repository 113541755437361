import React from 'react';
import { Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import KhushuPattern from '../../assets/img/Khushu-Pattern.png';




const useStyles = makeStyles((theme) => ({
    FD_banner_img: {
        background: `#8ba4b9 url(${KhushuPattern})`,
        backgroundSize: "cover",
        opacity: 1,
        backgroundPosition: "center",
        paddingTop: "8px",
        paddingBottom: "8px",
        [theme.breakpoints.down('xs')]: {
            paddingTop: "40px",
            paddingBottom: "40px",
        }
    },
    FD_inner_banner: {
        padding: '24px'
    },
    FD_Hometitle: {
        fontWeight: "800",
        fontSize: "48px",
        margin: "10px 0",
        letterSpacing: "0.48px",
        color: "white",
        maxWidth: '600px',
        lineHeight: 1.1,
        [theme.breakpoints.down('xs')]: {
            fontSize: "21px",
            maxWidth: "100%",
            lineHeight: "inherit"
    },
    },
    FD_homeBanner: {
        textAlign: "center",
        color: "#fff",
        padding: "0 140px",
        [theme.breakpoints.down('xs')]: {
            padding: "0 64px"
    },
    '@media (max-width: 599.95px)': {
        padding: "0 35px",
    },
    },
    FD_line : {
        background: "#DAE2E9",
        height: "3px",
        maxWidth: "100px",
        borderRadius: "100px",
        margin: "30px auto",
    },
    FD_verse: {
        color: "white",
        fontSize: "20px",
        opacity: 0.8,
        fontWeight: 600,
        fontFamily: "Open Sans",
        margin: "16px 0px"
    },
    FD_donor: {
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "16px",
        color: "white",
    }
    }));


const Banner = (props) =>  {
    const {connectingPara} = props;

    const classes = useStyles();
    return (
    <Grid  className={classes.FD_banner_img} >
        <Grid container className={classes.FD_footerInner} >
            <Grid className={classes.FD_homeBanner} container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                
                <Grid container xs={12} direction="row" justify="center" >
                    <Typography  component="div">
                        <Typography className={classes.FD_donor}>
                            {connectingPara}    
                        </Typography>
                        <Typography className={classes.FD_Hometitle} style={{paddingTop: '51px'}} component="h2">
                            The Reward of Goodnes 
                        </Typography>
                        <Typography className={classes.FD_Hometitle} style={{paddingBottom: '51px !important'}} component="h2">
                            is Nothing but Goodness
                        </Typography>
                        <Divider className={classes.FD_line} variant="fullWidth" />
                        <Typography className={classes.FD_verse}>
                            AL-QURAN 55:61
                        </Typography>
                    </Typography>
                    

                    
                </Grid>

            </Grid>
        </Grid>
      

    </Grid>
    );
}

export default Banner;
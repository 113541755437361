
import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import organizationIMg from '../../assets/img/organization_img.png';
import listIcon from '../../assets/img/list_icon.png';



const useStyles = makeStyles((theme) => ({
    FD_tabsImgText: {
        padding: '0px 0px',
        maxWidth: "1140px",
        margin: "0 auto",
        width: '100%',
        height: 'auto',
    },
    FD_organizationImg: {
        paddingLeft: '0px',
        paddingRight: '0px',
        width: '100%'
    },
    FD_sideTextPart: {
        fontFamily : 'Open Sans',
        color: "#4C5E67",
        textAlign: "left",
        fontSize: "22px",
        lineHeight: 1.8,
        margin: 0,
        fontWeight: 600,
        '@media(max-width:425px)': {
            fontSize: '18px',
        }

    },
    FD_listUl: {
        paddingLeft: 0,
        listStyle: "none",
    },
    FD_listItem: {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        color: "#294C5C",
        padding: '0px',
        margin: '30px 0px',
        '& .MuiTypography-body1': {
        fontFamily : 'Open Sans',
        }

    },
    FD_listItemText: {
        fontFamily : 'Open Sans',
    },
    FD_listImg: {
        paddingRight: "10px",
    },
    FD_noticeTitle: {
        fontSize: "28px",
        fontWeight: "bold",
        margin: "10px 0",
    },
    FD_whiteLine: {
        background: "#fff",
        height: "3px",
        maxWidth: "100px",
        borderRadius: "100px",
        margin: "10px auto",
    },
    FD_noticeText: {
        fontFamily : 'Open Sans',
        fontSize: "18px",
        opacity: 0.8,
        letterSpacing:'1px', 
    },
    tabContainer : {
        justifyContent: 'space-between',
        display: 'block' 
    },
    FD_marginAuto : {
        margin: "0 auto",
        padding : 0
    },
    TabsList2: {
        padding: '0 0 0 16px !important',
        // paddingLeft: '16px',
        '@media(max-width:785px)': {
            paddingLeft: '0px !important',
            position: 'relative',
            bottom: '50px',
        
        }
    },
    TabsList1: {
        padding: '0px', 
        paddingLeft: '16px',
        '@media(max-width:785px)': {
            paddingLeft: '0px'
        }
    },
    listParent: {
        padding: '0px',
        width: '100%', 
        margin: '0', 
        paddingTop: '18px',
        '@media(max-width:785px)': {
            display: 'block'
        }
    }

    }));



const TabContent = (props) =>  {

    const classes = useStyles();

    return (
        <Grid container direction="row" spacing="4" alignItems="stretch" className={classes.FD_tabsImgText}>
        {/* <Grid item className={classes.FD_marginAuto } style={{padding: '0px'}} md="4">
            <img src={organizationIMg}
            className={classes.FD_organizationImg}
            alt="" />
        </Grid> */}
        <Grid item md="12" style={{padding: '0px 24px 0px 24px'}}>
        <Typography className={classes.FD_sideTextPart} variant="h2" component="h2">
            {props.heading }
        </Typography>
        <Grid container 
        className={classes.listParent} 
        // style={{padding: '0px', width: '100%', margin: '0', paddingTop: '30px'}} 
        spacing="4">
            <Grid item md="4" style={{padding: '0px'}} >
            <List className={classes.FD_listUl}>
                <ListItem className={classes.FD_listItem}>
                    <ListItemIcon style={{minWidth: '40px'}}>
                        <img src={listIcon} alt='' />
                    </ListItemIcon>
                    <ListItemText className={classes.FD_listItemText} style={{fontSize: '14px'}}>
                        Make donations with no worries
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.FD_listItem}>
                    <ListItemIcon style={{minWidth: '40px'}}>
                        <img src={listIcon} alt='' />
                    </ListItemIcon>
                    <ListItemText className={classes.FD_listItemText} style={{fontSize: '14px'}}>
                        Secured transactions
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.FD_listItem}>
                    <ListItemIcon style={{minWidth: '40px'}}>
                        <img src={listIcon} alt='' />
                    </ListItemIcon>
                    <ListItemText className={classes.FD_listItemText} style={{fontSize: '14px'}}>
                        Robust payment processing
                    </ListItemText>
                </ListItem>
            </List>
            </Grid>

            <Grid item md="4" 
            className={classes.TabsList2} >
            <List className={classes.FD_listUl}>
                <ListItem className={classes.FD_listItem}>
                    <ListItemIcon style={{minWidth: '40px'}}>
                        <img src={listIcon} alt='' />
                    </ListItemIcon>
                    <ListItemText className={classes.FD_listItemText} style={{fontSize: '14px'}}>
                        Safeguard personal & payment info
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.FD_listItem}>
                    <ListItemIcon style={{minWidth: '40px'}}>
                        <img src={listIcon} alt='' />
                    </ListItemIcon>
                    <ListItemText className={classes.FD_listItemText} style={{fontSize: '14px'}}>
                        All organizations are verified
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.FD_listItem}>
                    <ListItemIcon style={{minWidth: '40px'}}>
                        <img src={listIcon} alt='' />
                    </ListItemIcon>
                    <ListItemText className={classes.FD_listItemText} style={{fontSize: '14px !important'}}>
                        Credentials verified & protected
                    </ListItemText>
                </ListItem>
            </List>
            </Grid>

        </Grid>
        </Grid>
        </Grid>
    )
}

export default TabContent;
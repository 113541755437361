import React from 'react';
import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/img/footer_Logo.png';
import Typography from '@material-ui/core/Typography';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { Link } from 'react-router-dom';
import './style.css';







const useStyles = makeStyles((theme) => ({
    footer: {
        borderBottom: "1px solid #0000001A",
        background: "#f7f9fa",
        padding: "58px 0",
        paddingBottom: "24px",

        '@media(max-width:768px)': {
            textAlign: 'center',
        }
    },
    FD_footerInner:{
        maxWidth: "1140px",
        margin: "0 auto"
    },
    logoSection: {
        padding: '8px'
    },
    FD_footerlogoText : {
        color: "#4C5E67",
        fontSize: "18px",
        lineHeight: "31px",
        margin: "14px 0",
        fontFamily: 'Open Sans',

        '@media(max-width:768px)': {
            textAlign: 'center',
        }
    },
    FD_footer_column1: {
        textAlign: "center",
        margin: "16px auto",
        [theme.breakpoints.down('xs')]: {
            alignItems: "flex-start",
            margin: "0px"
          }
 
    },
    FD_footer_column2:{
        margin: "16px auto",
        padding: "0px",
        [theme.breakpoints.down('sm')]: {
            alignItems: "flex-start",
            margin: "0px"
          }
    },
    FD_footer_title: {
        color: "#294C5C",
        fontFamily: "Roboto",
        fontSize: '22px',
        fontWeight: "bold",
        '@media(max-width:768px)': {
            textAlign:"center",
        }
    },
    FD_footer_li: {
        color: "#4C5E67",
        opacity: "0.8",
        fontSize: "14px",
        padding: '6px 0',

        '@media(max-width:768px)': {
            textAlign: 'center',
        }
    },
    FD_footer_ul: {
        fontSize: "14px",
        margin: "16px 0px 16px 0px",
        padding: "0px",
        '@media(max-width:768px)': {
            textAlign:"center",
        }
    },
    FD_footer_text: {
        margin: "0px",
        '& .MuiTypography-body2': {
            color: "#4C5E67",
            fontSize: "18px",
            lineHeight: "31px",
            opacity: 0.8,
            fontFamily: 'Open Sans',
        }
    },
    FD_social_img_tag_fb: {
        // background: "#666",
        position: "relative",
        // padding: "5px 11px",
        
        marginTop: "16px",
        borderRadius: "32px",
        padding: '7px 11px',
        color: '#666',
        border: '1px solid #666',

        '&:hover': {
            background: '#666',
            color: 'white',
            transition: '0.8s',
            left: 0,
            
        },

        '@media(max-width:768px)': {
            verticalAlign:"top",
        },
    },
    social_img_tag_twitter: {
        padding: "5px",
        border: "1px solid #666",
        borderRadius: "33px",
        marginTop: "16px",
        height: "20px",
        width: "20px",
        color: "#666",
        marginLeft: "5px",
        '&:hover': {
            transition: '1.5s',
            background: '#666',
            color: 'white',
        }
    },
    Link: {
        textDecoration: 'none',
        color: 'inherit'
      },
    }));


const Footer = () =>  {

    const classes = useStyles();
    return (
        <div className="footer_wrapper">
            
            <Grid className={classes.footer} >
                <div className="main_container">
                <Grid container className={classes.FD_footerInner} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
            
                        <Grid item md={5} sm={6} xs={12} className={classes.logoSection}  >
                            <div>
                            <img src={logo} alt="logo" />
                            </div>
                            <Typography className={classes.FD_footerlogoText} variant="body2">
                                DEENFUND is a global platform connecting awesome donors to amazing organizations with great causes.
                            </Typography>
                        </Grid>
                    <Grid container md={4} sm={6} xs={12} alignItems="flex-start" justify="flex-start" direction="column" >
                        <Grid item className={classes.FD_footer_column1}>
                        <Typography  className={classes.FD_footer_title}>
                            DEENFUND
                        </Typography>
                        <List className={classes.FD_footer_ul} >
                        <Link to="/" className={classes.Link}>
                                <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Home" /></ListItem>
                        </Link>
                        <Link to="/about-us#about" className={classes.Link}>
                                <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="About Us" /></ListItem>
                        </Link>

                        <Link to="/about-us#feature" className={classes.Link}>
                                <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Features" /></ListItem>
                        </Link>
                            
                        <Link to="/about-us#pricing" className={classes.Link}>
                                <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Pricing" /></ListItem>
                        </Link>

                        <Link to="/about-us#faq" className={classes.Link}> 
                            <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="FAQs" /></ListItem>
                        </Link>
                            
                        <Link to="/about-us#contact-us" className={classes.Link}> 
                            <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Contact Us" /></ListItem>
                        </Link>
                        </List>
                        </Grid>
                    </Grid>
                    <Grid container md={3} sm={6} xs={12} alignItems="flex-start" justify="flex-start" direction="column" >
                        <Grid item className={classes.FD_footer_column2}>
                        <Typography className={classes.FD_footer_title}>
                        DISCOVER
                        </Typography>
                        <List className={classes.FD_footer_ul} >
                        <Link to="/about-us#how-it-works-for-donors" className={classes.Link}> 
                            <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="How it Works For Donors" /></ListItem>
                        </Link>
                        <Link to="/about-us#how-it-works-for-orgs" className={classes.Link}> 
                            <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="How it Works For Orgs" /></ListItem>
                        </Link>
                        <Link to="/#recent_Joind_organization" className={classes.Link}>  
                            <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Make Donations" /></ListItem>
                        </Link>
                            <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Add Organization" /></ListItem>
                            <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Org Secured Login" /></ListItem>
                        </List>
                        </Grid>
                    </Grid>

                
               {/* <Grid container md={3} sm={6} xs={12} alignItems="center" justify="flex-start" direction="column" >
                   <Grid item className={classes.FD_footer_column1}>
                   <Typography className={classes.FD_footer_title} >
                    FOLLOW US
                    </Typography>
                    <Grid item style={{display: 'flex'}} >
                      <i className={'fa fa-facebook'+' '+classes.FD_social_img_tag_fb} aria-hidden="true"></i>
                        <TwitterIcon className={classes.social_img_tag_twitter} />
                        <YouTubeIcon className={classes.social_img_tag_twitter} />
                    </Grid>
                </Grid> 
                   

            </Grid> */}
               

            </Grid>
        </Grid>
      
        </div>
    </Grid>

    </div>
    );
}

export default Footer;
import React from 'react'
import Navigation from './navigations'
import './App.css'

function App () {
  return (
      <Navigation />
  )
}

export default App
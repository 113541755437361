import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import brandLogo from '../../assets/img/dc_logo.png';
import { Link } from 'react-router-dom';

import DcLogo from './DC_Logo.png';

import './style.css';




const useStyles = makeStyles((theme) => ({
    footer: {
        borderBottom: "1px solid #0000001A",
        background: "#f7f9fa",
        paddingTop: "40px",
        paddingBottom: "39px",
    },
    FD_footerInner: {
        maxWidth: "1140px",
        margin: "0 auto",
        paddingLeft: "15px",
        paddingRight: '15px',
        
    },
    FD_FooterGrid: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
          }
    },
    FD_lowerfooter: {
        color: "#4C5E67",
        fontSize: "12px",
        lineHeight: "1.5",
        paddingRight: "10px",
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            alignItems: "center"
          },
          '@media(max-width:425px)': {
        paddingRight: "0px",
        }
        
    },
    FD_smallText: {
        textAlign: "right",
        fontSize: "12px",
        color: "#818E94",
        fontWeight: 500
    },
    FD_dee: {
        position: "relative",
        top: "5px",
        width: "18px",
    },
    link: {
        textDecoration: 'none',
        color: "#4C5E67"
    },
    mobileLowerFooter: {
        '@media(max-width:960px)': {
            paddingBottom: "10px",
            }
    }
    }));


const LowerFooter = () =>  {

    const classes = useStyles();
    return (


        <div>

                <div className="ftr_btm_grey">
                    <div className="footer_container">
                        <div className="rows">
                        <div className="clom-6">
                                <div className="tx_box term_links"><Link  to="/terms-policies">TERMS OF SERVICES</Link> <span>|</span>
                                    <Link to="/privacy-policy"> PRIVACY POLICY</Link>
                                </div>
                            </div>
                            <div className="clom-6">
                                <div className="tx_box text-right">Crafted With<i className="fa fa-heart" aria-hidden="true"></i>In Islam
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div className="ftr_btm">
                    <div className="footer_container">
                        <div className="rows">
                            
                            <div className="clom-4 ver_aling">
                                <div className="tx_box">
                                © 2020 deeconnect. ALL RIGHT RESERVED
                                </div>
                            </div>
                            <div className="clom-4 ver_aling">
                                
                                <div className="tx_box">
                                    <div className="powered_by"> Powered by
                                        <a href="#"><img className="dc_logo_ftr" src={DcLogo} alt="DC Logo" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="clom-4 ver_aling right_aligh">
                                <div className="tx_box text-right">
                                    Visit us - <a href="https://deenconnect.io/" target="_blank">www.deenconnect.io</a> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        </div>
    
    );
}

export default LowerFooter;
import React from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'

import { Home, About, Terms, Privacy } from '../screens';
import EditorComponent from '../screens/Editor/EditorComponent';
import Uppload from '../screens/Uppload/Uppload';
import PageNotFound from '../screens/PageNotFound';


function Navigations() {
  return (
    <Router>
      <Route exact path='/privacy-policy' component={Privacy} />
      <Route exact path='/terms-policies' component={Terms} />
      <Route exact path='/about-us' component={About} />
      <Route exact path='/editor' component={EditorComponent} />
      <Route exact path='/uppload-example' component={Uppload} />
      <Route exact path='/' component={Home} />
      <Route exact path='/PageNotFound' component={PageNotFound} />

      {/* <Redirect to={"/"} />   */}
    </Router>

  )
}

export default Navigations

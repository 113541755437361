import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    FD_pagePart: {
        background: "#05CE78",
        textAlign: "center",
        height: "52px",
        '@media (max-width:370px)' :  {
            height: "auto",
        }
    },
    FD_padding_zero: {
        padding: "0 !important"
    },
    tabsStyles: {
        width:"auto",
        color: "white",
        fontWeight: 500
    },
    singleTab: {
      height: "52px",
      marginTop: "0px",
      minWidth: "auto",
      padding: "0px 24px 0px 24px",
      opacity: 'inherit',
      '@media (max-width:370px)' :  {
        display : 'block',
        borderBottom : 'unset',
        margin: '0 auto'
    }
    },
    bigIndicator: {
      height: 5,
      backgroundColor: "white",
      '@media (max-width:370px)': {
        display: 'none'
    },
    },
    activeTextColor: {
      color: "white",
      fontWeight: "bold",
      '@media (max-width:370px)': {
        borderBottom: '5px solid white'
    },
    },
    Link: {
        textDecoration: 'none',
        color: 'white'
    },
    scroller :  {
        whiteSpace: "normal",
    },
    tabContainer : {
        display: 'block' 
    },

    }));



const TabsHeader = (props) =>  {

    let active =  props.active;

    const handleChange = (event, newValue) => {
    active = newValue;
    };

    const classes = useStyles();
    return (
        <Grid   className={classes.FD_pagePart} >
        <Grid container className={classes.FD_padding_zero} >
            <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >

                <Grid container xs={12} justify="center" >
                <Tabs
                className={classes.tabsStyles}
                    value={active}
                    classes={{indicator: classes.bigIndicator, scroller : classes.scroller, flexContainer : classes.tabContainer}}
                    onChange={handleChange}
                    aria-label="tab"
                    
                    
                >
                   <Link to="/terms-policies" className={classes.Link}>
                        <Tab className={classes.singleTab+" "+(active === 0 ? classes.activeTextColor : '' )} label="TERMS OF SERVICE" />
                   </Link>
                   <Link to="/privacy-policy" className={classes.Link}>
                        <Tab className={classes.singleTab+" "+(active === 1 ? classes.activeTextColor : '' )} label="PRIVACY POLICY" />
                    </Link>
                </Tabs>
                </Grid>

            </Grid>
        </Grid>
    </Grid>
    );
}

export default TabsHeader;
import React, { useEffect } from 'react'
import { Uppload, Instagram, Local, xhrUploader, en, Crop, Brightness,Blur,Contrast,Rotate, Flip, Grayscale,HueRotate,Sepia } from "uppload";
import "uppload/dist/uppload.css";
import "uppload/dist/themes/light.css";

export default function UpploadComponent() {

    useEffect(() => {
        const uploader = new Uppload({
            value: "https://via.placeholder.com/150",
            lang: en,
            uploader: xhrUploader({
              endpoint: "http://shapla.deenfund.com"
            }),
            bind: document.querySelector("img.uppload-pic"),
            call: document.querySelector("button.pic-btn")
          });
          uploader.use([
            //   new Instagram(),
              new Local(), 
              new Crop(), 
              new Brightness(),
              new Blur(),
              new Rotate(),
              new Contrast(),
              new Flip(),
              new Grayscale(),
              new HueRotate(),
              new Sepia(),
            ])
    }, [])


    return (
        <div>
            <img className="uppload-pic" alt="uppload" />
            <button className="pic-btn">Upload</button>
        </div>
    )
}
